import Formula from "formula"

import { extend } from "helper"

extend([ String.prototype ],
{
    fromJson: function(instance)
    {
        const obj = JSON.parse(this)
        if (instance)
        {
            Object.assign(instance, obj)
            return instance
        }
        return obj
    },
    humanize: function()
    {
        const math = Formula.mathjs
        
        if (this.endsWith(" h") || this.endsWith(" hrs") || this.endsWith(" hours"))
        {
            let days = parseFloat(this) / 24
            let hours = 24 * (days - math.floor(days))
            let minutes = 60 * (hours - math.floor(hours))
            let seconds = 60 * (minutes - math.floor(minutes))
            let milliseconds = 1000 * (seconds - math.floor(seconds))
            
            days = math.floor(days)
            hours = math.floor(hours)
            minutes = math.floor(minutes)
            seconds = math.floor(seconds)
            milliseconds = math.round(milliseconds)
            
            const o = []
            
            if (          days != 0) o.add(        days + "d")
            if (         hours != 0) o.add(       hours + "h")
            if (       minutes != 0) o.add(     minutes + "m")
            if (       seconds != 0) o.add(     seconds + "s")
            if (  milliseconds != 0) o.add(milliseconds + "ms")
            
            if (!o.length)
                o.add(0)
            
            return o.join(" ")
        }
        
        const o = this.split('.')
        
        const precision = math.config.precision
        
        if (o.length == 2 && o[1].length == precision)
        {
            const p = o[1].substr(0, precision - 1).split('')
            
            if (p.every(c => c == '9'))
                return (+o[0] + 1).toString()
            
            if (p.every(c => c == '0'))
                return (+o[0]).toString()
        }
        
        return this
    }
})